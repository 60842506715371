/* Axios instance's header without `x-user-token` for authentication server. */

import axios from "axios";
import services from "@/constants/env/services";
import { apiWrapperGenerator } from "./lib";

const basicAuthenticationServer = axios.create({
  baseURL: services.admin,
});

basicAuthenticationServer.interceptors.request.use(
  async (config) => config,
  (error) => Promise.resolve(error)
);

const apiWrapper = apiWrapperGenerator(basicAuthenticationServer);
const serverToken = "rG5kXk0CDbhgF4RBlNoV";

export async function getEncodedTerm(password) {
  try {
    const { data } = await basicAuthenticationServer.get(`/v1/auth/encode-term?text=${password}`);
    return data.item;
  } catch (error) {
    console.log(error);
  }
}

export async function setInvitedUser(user) {
  const encodedPassword = await getEncodedTerm(user.password);
  const data = Object.assign({}, user, { password: encodedPassword });
  return await basicAuthenticationServer.post("/invite/v2/setuser", data);
}

export async function setFreeTrailInvitedUser(user) {
  const encodedPassword = await getEncodedTerm(user.password);
  const data = Object.assign({}, user, { password: encodedPassword });
  return await basicAuthenticationServer.post("/v1/self-signup/setuser", data);
}

export async function resetNewPassword(payload) {
  const encodedPassword = await getEncodedTerm(payload.password);
  const user = { token: payload.token, password: encodedPassword };
  return await basicAuthenticationServer.post("/reset/v2/setpwd", user);
}

export async function employeeIdLogin(payload) {
  const data = { ...payload, token: serverToken };
  return await apiWrapper("post", "/v1/auth/employeeId-account/login", data);
}

export async function refreshEmployeeIdLogin(refreshToken) {
  const tokens = { token: serverToken, refreshToken };
  return await apiWrapper("post", "/v1/auth/employeeId-account/refresh", tokens);
}

export async function sharedAccountLogin(payload) {
  const encodedURIPassword = encodeURIComponent(payload.password);
  const encodedPassword = await getEncodedTerm(encodedURIPassword);
  const data = { ...payload, token: serverToken, password: encodedPassword };
  return await apiWrapper("post", "/v1/auth/shared-account/login", data);
}

export async function refreshSharedAccountLogin(refreshToken) {
  const tokens = { token: serverToken, refreshToken };
  return await apiWrapper("post", "/v1/auth/shared-account/refresh", tokens);
}
